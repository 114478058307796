/* Component Dependencies */
var adaptiveHeadingTemplate = require('templates/adaptiveHeading.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({

  type: 'adaptiveHeading',

  template: {
    'adaptiveHeading': adaptiveHeadingTemplate
  }
});
